<template>
  <v-container class="payment-type payment-type-nexi px-0 px-sm-auto">
    <div class="nexi-info-label mb-4">
      {{ $t("payment.paymentTypeRedirect.info") }}
    </div>
    <div
      class="payment-type-title"
      v-html="paymentType.title"
      v-if="paymentType.title"
    ></div>
    <span class="descr" v-html="paymentType.descr"></span>

    <PrebilledAmount
      :mode="mode"
      :paymentTypeId="paymentType.paymentTypeId"
      :order-id="orderId"
    />
    <PaymentAuthList
      @selectPaymentAuth="selectPaymentAuth"
      @addNewCard="addNewCard"
      :paymentTypeId="paymentType.paymentTypeId"
      :showRememberAlert="false"
    />
    <div class="d-flex flex-row align-center" v-if="options.showRememberCard">
      <v-btn href="#" color="primary" @click="addNewCard" small>
        {{ $t("paymentTypeList.addNewCard") }}
      </v-btn>
      <v-checkbox
        v-model="rememberNewCard"
        :label="$t('paymentTypeList.rememberNewCard')"
      />
    </div>
    <ResponseMessage :response="response" />

    <v-row
      class="d-flex justify-end align-center mt-5"
      v-if="mode != 'order' && !options.hidePrevButton"
    >
      <LinkButton
        v-if="
          !$vuetify.breakpoint.xs &&
            options.prevButton &&
            optionSs.prevButton.type === 'link-button'
        "
        :class="options.prevButton.class"
        :options="options.prevButton.options"
        :label="options.prevButton.label"
        @click="goBack"
      ></LinkButton>
      <v-btn
        v-else
        outlined
        color="primary"
        depressed
        v-on:click="goBack"
        :disabled="loading"
        large
        >{{ $t("common.back") }}
      </v-btn>
      <v-btn
        v-if="options.confirm.show"
        color="primary"
        class="mt-3 mt-sm-0 ml-2 gateway-btn"
        v-on:click="buy"
        depressed
        large
      >
        {{ $t("payment.paymentTypeList.proceed") }}
      </v-btn>
    </v-row>
    <i18n
      v-if="$te(`paymentTypeList.${paymentType.gatewayClient}.caption`)"
      :path="`paymentTypeList.${paymentType.gatewayClient}.caption`"
      tag="div"
      class="caption"
    />
  </v-container>
</template>
<script>
import PrebilledAmount from "./PrebilledAmount";
import PaymentAuthList from "./PaymentAuthList";
import ResponseMessage from "@/components/ResponseMessage";
import { payment, handlePayResponseKo } from "./gatewayUtilsService";
import OrderService from "~/service/orderService";
import gateway from "~/mixins/gateway";
import LinkButton from "~/components/LinkButton.vue";

import { mapActions } from "vuex";

export default {
  name: "PaymentTypeNexi",
  mixins: [gateway],
  data() {
    return {
      loading: false,
      error: null,
      selectedPaymentAuthId: null,
      rememberNewCard: false,
      response: {}
    };
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { PrebilledAmount, PaymentAuthList, ResponseMessage, LinkButton },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart"
    }),
    async buy() {
      this.loading = true;
      try {
        let paymentData = { remember_card: this.rememberNewCard };
        if (this.mode == "cart") {
          let data = await this.buyCart({
            paymentTypeId: this.paymentType.paymentTypeId,
            userPaymenthAuthId: this.selectedPaymentAuthId,
            paymentData: paymentData
          });
          if (data) {
            this.payResponseOk(data);
          } else {
            handlePayResponseKo(data, this.orderId);
          }
        } else if (this.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            this.orderId,
            this.paymentType.paymentTypeId,
            this.selectedPaymentAuthId,
            paymentData
          );
          if (response) {
            this.payResponseOk(response);
          } else {
            handlePayResponseKo(response, this.orderId);
          }
        }
      } catch (err) {
        this.response = err;
      } finally {
        this.loading = false;
      }
    },
    async addNewCard() {
      try {
        let paymentData = { remember_card: this.rememberNewCard };
        let cart = await this.buyCart({
          paymentTypeId: this.paymentType.paymentTypeId,
          paymentData: paymentData
        });
        if (cart) {
          this.payResponseOk(cart);
        } else {
          handlePayResponseKo(cart, this.orderId);
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    payResponseOk(cart) {
      let orderId = null;

      global.EventBus.$emit("pay-response-ok", cart);
      if (cart && cart.paymentUrl) {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        payment(cart.paymentUrl, cart.paymentParameters, "post", orderId);
        this.loading = false;
      } else {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        this.$router.push("/cart/payment-completed/" + orderId);
        this.loading = false;
      }
    },
    goBack() {
      this.$router.push("/checkout");
    },
    selectPaymentAuth(paymentAuthId) {
      this.selectedPaymentAuthId = paymentAuthId;
    }
  },
  toggleRememberCard(value) {
    this.rememberNewCard = value;
  }
};
</script>
